import request from '@/network/request';


/**
 * ���������б�
 */
export async function GetAgentApplies(data) {
    return request.post('/AgentApply/GetApplies', data);
}


/**
 * �����������
{
  "id": '���ID',
  "stated": '1:ͨ�� 2:����',
  "auditNote": "��˱�ע"
}
 */
export async function AuditAgentApply(data) {
    return request.post('/AgentApply/AuditApply', data);
}
