<template>
    <div class="userManagement-container">
        <vab-query-form>
            <el-form :inline="true" :model="queryForm" @submit.native.prevent>
                <el-form-item>
                    <el-select v-model="queryForm.stated" placeholder="请选择">
                        <el-option v-for="item in agentApplyStated"
                                   :key="item.value"
                                   :label="item.name"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input v-model.trim="queryForm.customerName"
                              placeholder="请输入用户名称"
                              clearable />
                </el-form-item>
                <el-form-item>
                    <el-input v-model.trim="queryForm.customerMobile"
                              placeholder="请输入用户手机"
                              clearable />
                </el-form-item>
                <el-form-item>
                    <el-button icon="el-icon-search" type="primary" @click="queryData">
                        查询
                    </el-button>
                </el-form-item>
            </el-form>

        </vab-query-form>

        <el-table v-loading="listLoading"
                  :data="list"
                  border
                  :element-loading-text="elementLoadingText"
                  @selection-change="setSelectRows">


            <el-table-column show-overflow-tooltip label="操作" width="100">
                <template #default="{ row }">
                    <el-button type="primary" @click="audit(row)" v-if="row.stated===0">审核</el-button>
                </template>
            </el-table-column>


            <el-table-column show-overflow-tooltip
                             width="60px"
                             prop="id"
                             label="ID"></el-table-column>

            <el-table-column show-overflow-tooltip
                             width="200px"
                             prop="code"
                             label="申请编号"></el-table-column>


            <el-table-column show-overflow-tooltip
                             width="110px"
                             prop="mobile"
                             label="代理商手机">
            </el-table-column>

            <el-table-column show-overflow-tooltip
                             width="180px"
                             prop="idNo"
                             label="代理商身份证">
            </el-table-column>




            <el-table-column show-overflow-tooltip
                             width="120px"
                             prop="customerName"
                             label="申请者姓名">
            </el-table-column>

            <el-table-column show-overflow-tooltip
                             width="120px"
                             prop="customerNickName"
                             label="申请者昵称">
            </el-table-column>

            <el-table-column show-overflow-tooltip
                             width="100px"
                             prop="stated"
                             label="状态">
                <template #default="{ row }">
                    <el-tag type="danger">
                        {{ agentApplyStated.find(d=>d.value==row.stated).name }}
                    </el-tag>
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip
                             width="160px"
                             prop="creationTime"
                             label="申请时间">
                <template slot-scope="scope">
                    {{scope.row.creationTime| DateFormat}}
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip
                             width="200px"
                             prop="note"
                             label="备注"></el-table-column>


            <el-table-column show-overflow-tooltip
                             width="160px"
                             prop="auditTime"
                             label="审核时间">
                <template slot-scope="scope">
                    {{scope.row.auditTime| DateFormat}}
                </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip
                             width="200px"
                             prop="auditNote"
                             label="审核备注"></el-table-column>


        </el-table>

        <el-pagination background
                       :current-page="queryForm.pageIndex"
                       :page-size="queryForm.pageSize"
                       :layout="layout"
                       :total="total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"></el-pagination>

        <edit ref="edit" @fetch-data="fetchData"></edit>
    </div>
</template>

<script>
    import { GetAgentApplies } from '@/api/agent';
    import Edit from './components/verify';
    var enumData = require('@/config/data');
    export default {
        name: 'ApplyList',
        components: { Edit },
        data() {
            return {
                agentApplyStated: enumData.agentApplyStated,
                list: null,
                listLoading: false,
                layout: 'total, sizes, prev, pager, next, jumper',
                total: 0,
                selectRows: '',
                elementLoadingText: '正在加载...',
                queryForm: {
                    pageIndex: 1,
                    pageSize: 10,
                    customerName: '',
                    customerMobile: '',
                    stated: -1
                },
            }
        },
        created() {
            this.fetchData();
        },
        methods: {
            audit(row) {
                this.$refs['edit'].showEdit(row);
            },
            
            setSelectRows(val) {
                this.selectRows = val
            },
            handleSizeChange(val) {
                this.queryForm.pageSize = val;
                this.fetchData();
            },
            handleCurrentChange(val) {
                this.queryForm.pageIndex = val;
                this.fetchData();
            },
            queryData() {
                this.queryForm.pageIndex = 1;
                this.fetchData();
            },
            async fetchData() {
                const { isSucceed, message, result, total } = await GetAgentApplies(this.queryForm);
                if (!isSucceed) return this.$baseMessage(message, 'error');
                console.log(result)
                this.list = result;
                this.total = total;
            },
        },
    }
</script>

